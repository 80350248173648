import {
  makeStyles,
  createStyles,
  AppBar,
  Toolbar,
  Typography,
  Button,
  Container,
  IconButton,
  Menu,
  MenuItem
} from '@material-ui/core'
import React, { useState } from 'react'
import logo from '../images/halodao-logo.png'
import MenuIcon from '@material-ui/icons/Menu'
import TwitterIcon from '@material-ui/icons/Twitter'
import GitHubIcon from '@material-ui/icons/GitHub'
import MediumIcon from '../images/medium.svg'
import DiscordIcon from '../images/discord.svg'
import { navigate } from '@reach/router'
import { Link } from 'gatsby'

const useStyles = makeStyles(theme =>
  createStyles({
    appBar: {
      paddingTop: 20
    },
    logo: {
      marginRight: theme.spacing(2)
    },
    title: {
      flexGrow: 1,
      fontWeight: 700,
      textDecoration: 'none',
      color: 'inherit'
    },
    sectionDesktop: {
      display: 'none',
      [theme.breakpoints.up('sm')]: {
        display: 'flex'
      }
    },
    sectionMobile: {
      display: 'flex',
      [theme.breakpoints.up('sm')]: {
        display: 'none'
      }
    },
    linkButton: {
      marginLeft: theme.spacing(4),
      textTransform: 'none'
    },
    footer: {
      color: 'white',
      backgroundColor: '#5c2a72',
      paddingTop: 60,
      paddingBottom: 60,
      textAlign: 'center',
      [theme.breakpoints.up('sm')]: {
        paddingTop: 100,
        paddingBottom: 100
      }
    },
    socialButton: {
      width: 60,
      height: 60,
      borderRadius: 50,
      border: '1px solid white',
      marginRight: 30,
      color: 'white',
      [theme.breakpoints.up('sm')]: {
        width: 80,
        height: 80
      }
    }
  })
)

const Layout: React.FC = ({ children }) => {
  const classes = useStyles()
  const [moreMenuAnchorEl, setMoreMenuAnchorEl] = useState<null | HTMLElement>(
    null
  )

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setMoreMenuAnchorEl(event.currentTarget)
  }

  const handleMenuClose = () => {
    setMoreMenuAnchorEl(null)
  }

  return (
    <>
      <AppBar
        position="relative"
        color="transparent"
        elevation={0}
        className={classes.appBar}
      >
        <Container disableGutters={true}>
          <Toolbar>
            {/* <img height={50} src={logo} className={classes.logo} /> */}
            <Link to="/" className={classes.title}>
              <Typography component="h1" variant="h4">
                HaloDAO
              </Typography>
            </Link>
            <div className={classes.sectionDesktop}>
              <Button
                color="inherit"
                className={classes.linkButton}
                href="/Halo Token Model.pdf"
                target="_blank"
              >
                <Typography variant="h5">Whitepaper</Typography>
              </Button>
              <Button
                color="inherit"
                className={classes.linkButton}
                onClick={() => navigate('#footer')}
              >
                <Typography variant="h5">Follow Us</Typography>
              </Button>
            </div>
            <div className={classes.sectionMobile}>
              <IconButton
                aria-label="show more"
                aria-controls="more-menu"
                aria-haspopup="true"
                onClick={handleMenuOpen}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
            </div>
          </Toolbar>
        </Container>
      </AppBar>

      <Menu
        anchorEl={moreMenuAnchorEl}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        id="more-menu"
        keepMounted
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={moreMenuAnchorEl !== null}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={() => navigate('/team')}>
          <p>Our Team</p>
        </MenuItem>
        <MenuItem onClick={() => navigate('/careers')}>
          <p>Careers</p>
        </MenuItem>
        <MenuItem onClick={() => navigate('/Halo Token Model.pdf')}>
          <p>Whitepaper</p>
        </MenuItem>
        <MenuItem onClick={() => navigate('#footer')}>
          <p>Follow Us</p>
        </MenuItem>
      </Menu>

      <main>{children}</main>

      <footer className={classes.footer} id="footer">
        <a href="https://medium.com/halodao" target="_blank">
          <IconButton className={classes.socialButton}>
            <img height={30} src={MediumIcon} />
          </IconButton>
        </a>
        <a href="https://github.com/HaloDAO" target="_blank">
          <IconButton className={classes.socialButton}>
            <GitHubIcon fontSize="large" />
          </IconButton>
        </a>
        <a href="https://twitter.com/HaloDAOOfficial" target="_blank">
          <IconButton className={classes.socialButton}>
            <TwitterIcon fontSize="large" />
          </IconButton>
        </a>
        <a href="https://discord.gg/W5RNhprDnT" target="_blank">
          <IconButton className={classes.socialButton}>
            <img height={30} src={DiscordIcon} />
          </IconButton>
        </a>
      </footer>
    </>
  )
}

export default Layout
